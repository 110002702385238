import styled from '@emotion/styled';
import { Container } from '../../layout/components/container';

export const StyledHeader = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 var(--container-space);

  ${({ theme }) => theme.media.mobile} {
    justify-content: center;
  }

  a {
    box-shadow: none;
  }
`;

export const HeaderLink = styled.a`
  margin: 0 12px;
  color: ${({ theme }) => theme.textColor};
  font-size: 1.15rem;
  opacity: 0.8;

  &[aria-current='page'] {
    font-weight: bold;
  }
`;

export const Nav = styled.nav`
  position: sticky;
  top: 0;
  height: ${({ sticky }) => (sticky ? '60px' : '80px')};
  width: 100%;
  background: ${({ sticky, theme }) =>
    sticky ? theme.navigationBg : theme.backgroundColor};
  z-index: 10;
  transition: height 300ms ease;
  box-shadow: ${({ sticky }) => sticky && 'rgba(0, 0, 0, 0.15) 0 1px 4px 0'};

  ${({ theme }) => theme.media.mobile} {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px 0px;
    height: 60px;
  }
`;

export const MobileHeaderLink = styled(HeaderLink)`
  ${({ theme }) => theme.media.desktop} {
    display: none;
  }
`;

export const Logo = styled.a`
  color: ${({ theme }) => theme.textColor};
  font-size: 1.2rem;
  letter-spacing: -1px;
`;

export const LinksContainer = styled.div`
  display: flex;
  align-items: center;

  ${props => `
    ${props.theme.media.mobile}{
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: ${props.theme.backgroundColor};
      display: ${props.hidden ? 'flex' : 'none'};
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: ${props.theme.containerSpace};

      ${HeaderLink}, ${MobileHeaderLink} {
        padding-bottom: 40px;
        font-size: 1.8rem;
      }
    }
  `}
`;
