import React from 'react';
import { Global, css } from '@emotion/core';
import { useTheme } from 'emotion-theming';

const GlobalStyles = () => {
  const theme = useTheme();
  return (
    <Global
      styles={css`
        :root {
          --container-space: 1.5rem;
          --container-size-toc: 1100px;

          @media (max-width: 1100px) {
            --container-size-toc: 700px;
          }

          ${theme.media.mobile} {
            --container-space: 1.125rem;
          }
        }

        html {
          background: ${theme.backgroundColor};
        }

        // Make footer stick to bottom
        html,
        body,
        #___gatsby,
        #gatsby-focus-wrapper {
          height: 100%;
        }

        #gatsby-focus-wrapper {
          display: flex;
          flex-direction: column;
        }

        :focus {
          outline: ${theme.highlightColor} auto 3px;
        }

        a.anchor,
        .gatsby-resp-image-link {
          box-shadow: none;
        }

        a.anchor {
          padding-right: 6px;
          margin-left: -30px;

          svg {
            fill: ${theme.highlightColor};
            height: 24px;
            width: 24px;
          }
        }

        img {
          width: 100%;
          border-radius: 5px;
          margin: 0;
        }

        h1 {
          margin-top: 20px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          text-transform: capitalize;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        div,
        span,
        blockquote {
          color: ${theme.textColor};
        }

        a {
          color: ${theme.highlightColor};
          transition: all 0.2s ease;

          &:hover {
            color: ${theme.strongHighlightColor};
          }
        }

        blockquote {
          border-left: 0.2rem solid ${theme.strongHighlightColor};
          padding-left: 1.5rem;
        }

        ul {
          list-style: none;
          margin-left: 0;
          margin-bottom: 1.82rem;
          li {
            &:before {
              content: '\\2022';
              font-size: 44px;
              line-height: 0;
              vertical-align: middle;
              color: ${theme.strongHighlightColor};
              margin: -4px 6px 0 -4px;
              display: inline-flex;
            }
          }
        }
        .gatsby-resp-image-background-image {
          border-radius: 4px;
        }

        figure {
          ${theme.media.desktop} {
            width: calc(100% + 80px);
            margin-left: -40px;
          }
        }

        figcaption {
          text-align: center;
          color: ${theme.isDark
            ? 'rgba(241, 241, 241, 0.8)'
            : 'rgba(0, 0, 0, 0.60)'};
          margin-top: 5px;

          ${theme.media.desktop} {
            margin: 5px 40px 0;
          }
        }

        hr {
          background: ${theme.strongHighlightColor};
          height: 0;
          margin-bottom: 2.42rem;

          &:before {
            content: '–––';
            margin: 0 auto;
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            letter-spacing: 0.6rem;
            font-size: 2rem;
            line-height: 0;
          }
        }

        // Code pre styling
        :not(pre) > code[class*='language-'] {
          padding: 3px 5px;
        }

        :not(pre) > code[class*='language-'],
        pre[class*='language-'] {
          border-radius: 6px;
        }

        pre[class*='language-'] {
          margin-bottom: 2rem;
        }

        pre span {
          color: #f1f1f1;
        }

        // fix anchor headers hiding behind header
        :target::before {
          content: '';
          display: block;
          height: 100px; /* fixed header height*/
          margin: -100px 0 0; /* negative fixed header height */
        }

        .gatsby-highlight {
          ${theme.media.desktop} {
            width: calc(100% + 40px);
            margin-left: -20px;
          }
        }

        .hacker-news {
          background-color: #ffeadb;
          color: #ff6600;
          padding: 16px 20px;
          border-radius: 4px;
          text-align: center;
          margin: 0 auto;
          display: block;

          &:before {
            content: url('/images/y18.gif');
            height: 20px;
            width: 20px;
            position: relative;
            top: 2px;
            margin-right: 10px;
          }
        }
      `}
    />
  );
};

export default GlobalStyles;
